import { VIDEO_PLAYER_TYPE, VideoPlayerProps } from '.';
const VIDEO_PLAYER_ID = 'F2-cZcup6X4DpJuXo-bCod';
const VIDEO_PLAYER_ID_FOR_BACKGROUND_VIDS = 'D1rCWaT_n-UaJfGWbSk2ZN';
const VIDEO_PLAYER_SCRIPT_ID = 'mi24-js-script-id';

export function addDefaultValuesToMissingPropsValues(
  props: VideoPlayerProps,
): Omit<VideoPlayerProps, 'title'> {
  const videoPlayerProps: Omit<VideoPlayerProps, 'title'> = {
    cssID: props.cssID,
    inView: props.inView,
    loopType: props.loopType,
    playerType: props.playerType ?? VIDEO_PLAYER_TYPE.FOREGROUND,
    playerID: getVideoPlayerID(props),
    video: props.video,
    headline: props.headline,
    subtitle: props.subtitle,
    link: props.link,
    textColor: props.textColor,
    fallbackImg: props.fallbackImg,
  };

  return videoPlayerProps;
}

function addVideoPlayer(props: Omit<VideoPlayerProps, 'title'>): void {
  const {
    cssID: cssId,
    playerID: playerId,
    video: { videoId },
  } = props;

  const initPlayer = {
    success: function (playerApi) {
      playerApi.play();
      playerApi.toggleMute();
    },
    parameters: {
      mi24VideoPlayer: 'true',
      // disableAutoCreation: 'true',
      configType: 'vmpro',
      playerId,
      videoId,
      apiUrl: '//d.video-cdn.net/play',
      flashPath: '//e.video-cdn.net/v2/',
      token: '***',
    },
  };

  window.VideoPlayer.Collection.addPlayerById(cssId, initPlayer);
  window.VideoPlayer.Collection.rejectAnalytics();
}

export function addVideoPlayerScript(props: Omit<VideoPlayerProps, 'title'>): void {
  const script = document.createElement('script');

  document.head.appendChild(script);
  script.onload = () => addVideoPlayer(props);

  script.id = VIDEO_PLAYER_SCRIPT_ID;
  script.type = 'text/javascript';
  script.src = '//e.video-cdn.net/v2/embed.js';
  script.async = true;
}

export function getIsVideoPlayerScriptAvailable(): boolean {
  const id = VIDEO_PLAYER_SCRIPT_ID;

  if (isSSR()) return false;
  const mi24Script = document.getElementById(id);
  return Boolean(mi24Script);
}

export function getIsVideoPlayerInitialized(cssID: string): boolean {
  if (isSSR()) return false;
  const player = getVideoPlayer(cssID);

  return Boolean(player);
}

export function getVideoPlayer(cssID: string) {
  const id = cssID;

  //FIX: For mobile, the mi-cover needs to be clicked that the video player is loaded/created.
  const elements = document.querySelectorAll('#' + id + ' .mi-cover');
  if (elements) {
    for (const element of elements) {
      (element as HTMLVideoElement).click();
    }
  }
  let player = null;
  try {
    player = window.VideoPlayer?.Collection.getPlayerById(id);
  } catch (err) {
    // Ignore the error here, if the player was not found an exception is thrown that the player by id does not exists.
  }
  return player;
}

function getVideoPlayerID(props: VideoPlayerProps): string {
  if (props?.playerType === VIDEO_PLAYER_TYPE.BACKGROUND)
    return VIDEO_PLAYER_ID_FOR_BACKGROUND_VIDS;

  return VIDEO_PLAYER_ID;
}

function isSSR(): boolean {
  return typeof document === 'undefined' || typeof window === 'undefined';
}

export function removeVideoPlayerControls(cssID: string): void {
  if (isSSR()) return;

  const playerNode = document.querySelector(`#${cssID} video`);

  if (playerNode) {
    playerNode.removeAttribute('controls');
  }

  const muteIconNode = document.querySelector('[data-mi-player-role="mi-mute-icon"]');

  if (muteIconNode) {
    muteIconNode.remove();
  }
}
