import { useEffect, useRef, useState } from 'react';

// components
import { Image } from 'components/Image/Image';
import { CmsLink } from 'components/Link/CmsLink';
import { BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// hooks
import { useGetParent } from 'scenes/parent-provider';

// utils
import { Log } from 'services/log';
import { VIDEO_PLAYER_STATUS, VideoPlayerProps, VideoPlayerStatus } from '.';
import {
  addDefaultValuesToMissingPropsValues,
  addVideoPlayerScript,
  getIsVideoPlayerScriptAvailable,
  getVideoPlayer,
  getIsVideoPlayerInitialized,
  removeVideoPlayerControls,
} from './VideoPlayer.utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getHeadlineTag } from '../../ContentElements/Slider/Slider.utils';

// styles
import { VideoPlayerContainer } from './VideoPlayer.styles';

const DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION = 10000; // 10 seconds

export const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    cssID,
    inView,
    subtitle,
    headline,
    title: pictureTitle,
    link,
    textColor,
    fallbackImg,
    loopType,
    withPreviewId = true,
  } = props;

  const [isVideoPlayerScriptAvailable, setIsVideoPlayerScriptAvailable] = useState<boolean>(
    getIsVideoPlayerScriptAvailable,
  );
  const [isVideoPlayerInitialized, setIsVideoPlayerInitialized] = useState<boolean>(() => {
    return false;
  });
  const [hasVideoReachedEnd, setHasVideoReachedEnd] = useState<boolean>(false);

  const [hasControls, setHasControls] = useState<boolean>(true);

  const [status, setStatus] = useState<VideoPlayerStatus>(VIDEO_PLAYER_STATUS.LOADING);
  const [showPreviewImage, setShowPreviewImage] = useState<boolean>(true);
  const count = useRef<number>(0);
  const videoPlayer = useRef<any>(null);
  const shouldLoop = loopType === 'loop';
  const sliderContext = useGetParent();
  const headlineTag = getHeadlineTag(sliderContext);

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable) return;
    setIsVideoPlayerScriptAvailable(false);
  }, []);

  useEffect(() => {
    const shouldShowPreviewImage =
      status === VIDEO_PLAYER_STATUS.LOADING || status === VIDEO_PLAYER_STATUS.ERROR;

    setShowPreviewImage(shouldShowPreviewImage);
  }, [status]);

  useEffect(() => {
    if (isVideoPlayerScriptAvailable) return;
    if (getIsVideoPlayerScriptAvailable()) {
      setIsVideoPlayerScriptAvailable(true);
      return;
    }

    const videoPlayerProps = addDefaultValuesToMissingPropsValues(props);
    addVideoPlayerScript(videoPlayerProps);

    const wasAddingSuccessful = getIsVideoPlayerScriptAvailable();

    if (wasAddingSuccessful) {
      setIsVideoPlayerScriptAvailable(true);
    }
  }, [isVideoPlayerScriptAvailable, props]);

  useEffect(() => {
    // wait for video player to be initialized

    if (!isVideoPlayerScriptAvailable) return;
    if (isVideoPlayerInitialized) return;
    if (count.current > DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION) return;

    const intervalID = setInterval(() => {
      Log.info(`⏳ (${count.current}) waiting for video player to be initialized...`);

      if (getIsVideoPlayerInitialized(cssID)) {
        setIsVideoPlayerInitialized(true);
        clearInterval(intervalID);
      }

      if (count.current > DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION) {
        setIsVideoPlayerInitialized(false);
        clearInterval(intervalID);
      }

      count.current += 1000;
    }, 1000);

    return () => clearInterval(intervalID);
  });

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable) return;
    if (isVideoPlayerInitialized) return;

    const requiredProps = addDefaultValuesToMissingPropsValues(props);
    addVideoPlayerScript(requiredProps);
  }, []);

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable || !isVideoPlayerInitialized) {
      setStatus(VIDEO_PLAYER_STATUS.LOADING);
      setShowPreviewImage(true);
      return;
    }

    if (isVideoPlayerInitialized && isVideoPlayerScriptAvailable) {
      setStatus(VIDEO_PLAYER_STATUS.DONE);
      setShowPreviewImage(false);
      return;
    }

    setStatus(VIDEO_PLAYER_STATUS.DONE);
    setShowPreviewImage(false);
  }, [isVideoPlayerInitialized, isVideoPlayerScriptAvailable]);

  const initVideoPlayer = () => {
    if (!videoPlayer.current) {
      const player = getVideoPlayer(cssID);
      if (!player) {
        throw new Error('Video player not initialized');
      }
      videoPlayer.current = player;
    }
  };

  const configureVideoPlayer = () => {
    if (hasControls) {
      removeVideoPlayerControls(cssID);
      setHasControls(false);
    }

    // add event handlers
    if (shouldLoop) {
      const loopEventHandler = () => videoPlayer.current.play();
      inView
        ? videoPlayer.current.registerEventListener('ended', loopEventHandler)
        : videoPlayer.current.unregisterEventListener('ended', loopEventHandler);
    }

    if (!shouldLoop && !hasVideoReachedEnd) {
      const playOnlyOnceEndedEventHandler = () => {
        const _hasReachedEndOfVideo =
          videoPlayer.current.getDuration() - videoPlayer.current.getCurrentTime() < 0.5;

        if (_hasReachedEndOfVideo) {
          setHasVideoReachedEnd(true);
          setShowPreviewImage(true);
          return;
        }
        videoPlayer.current.play();
      };
      inView
        ? videoPlayer.current.registerEventListener('ended', playOnlyOnceEndedEventHandler)
        : videoPlayer.current.unregisterEventListener('ended', playOnlyOnceEndedEventHandler);
    }
  };

  const startVideo = () => {
    if (inView) {
      videoPlayer.current.play();
    }

    if (!inView) {
      videoPlayer.current.pause();
    }
  };

  useEffect(() => {
    // play / pause video in player
    if (status !== VIDEO_PLAYER_STATUS.DONE) return;

    initVideoPlayer();
    configureVideoPlayer();
    startVideo();
  }, [status, inView, shouldLoop, cssID, hasVideoReachedEnd, hasControls]);

  const renderLink = () => (
    <CmsLink link={link}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        color={textColor === 'white' ? BUTTON_COLORS.WHITE : BUTTON_COLORS.BLACK}
      >
        {link.text}
      </Button>
    </CmsLink>
  );

  return (
    <VideoPlayerContainer color={textColor} title={pictureTitle}>
      <div className="content">
        {(!!subtitle || !!headline) && (
          <>
            {!!subtitle && (
              <InnerHtml
                as="span"
                content={subtitle}
                className={classNameBuilder('subheadline', headlineTag === 'span' && 'block')}
                previewId={withPreviewId ? '#st_subline' : undefined}
              />
            )}
            {!!headline && (
              <InnerHtml
                as={headlineTag}
                content={headline}
                previewId={withPreviewId ? '#st_headline' : undefined}
                className={classNameBuilder('h2', headlineTag === 'span' && 'block')}
              />
            )}
            {!!link && <span>{renderLink()}</span>}
          </>
        )}
      </div>
      {showPreviewImage && <Image src={fallbackImg} alt={pictureTitle} title={pictureTitle} />}
      <div
        id={cssID}
        style={{
          visibility: showPreviewImage ? 'hidden' : 'visible',
          display: showPreviewImage ? 'none' : 'block',
        }}
      ></div>
    </VideoPlayerContainer>
  );
};
