import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import type { SliderTextColors } from '../../ContentElements/Slider';

export const VideoPlayerContainer = styled.div(
  ({ color, theme }: { color: SliderTextColors; theme: DefaultTheme }) => css`
    position: relative;
    flex-grow: 1;
    color: ${color};

    .mi-player {
      min-height: 250px !important;
      min-width: 442px !important;
    }

    img {
      overflow: hidden;
      display: flex;
      min-height: 250px;
      align-items: center;
      width: 100%;
    }

    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 10;
      width: 100%;
      max-width: 76.875rem;
      margin: 0 auto;
      transform: translate(-50%, -50%);
      min-height: 250px;
      left: 50%;
      top: 50%;
      padding: 6.25vw 2.5em 3.4375rem 10vw;
      height: 29.1666666667vw;

      a::before {
        display: none;
      }

      ${theme.mediaQueries.medium} {
        padding: 6.25vw 0 0 10vw;
      }

      .subheadline {
        text-transform: uppercase;
      }
    }
  `,
);
