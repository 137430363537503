import type { LoopType, SliderTextColors } from '../../ContentElements/Slider';

export type VideoProp = {
  type: 'mi24';
  videoId: string;
  videoPreviewImage: {
    desktop: string; // url
    mobile: string; // url
  };
};

export type VideoPlayerProps = {
  cssID: string;
  video: VideoProp;
  inView: boolean;
  loopType: LoopType;
  subtitle: string;
  headline: string;
  title: string;
  link: Link;
  textColor: SliderTextColors;
  fallbackImg: string;
  playerID?: string;
  playerType?: VideoPlayerType;
  withPreviewId?: boolean;
};

export const VIDEO_PLAYER_STATUS = {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
} as const;

type VideoPlayerValues<T> = T[keyof T];
export type VideoPlayerStatus = VideoPlayerValues<typeof VIDEO_PLAYER_STATUS>;

export const VIDEO_PLAYER_TYPE = {
  FOREGROUND: 'foreground',
  BACKGROUND: 'background',
} as const;

export type VideoPlayerType = VideoPlayerValues<typeof VIDEO_PLAYER_TYPE>;
