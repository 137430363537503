import React from 'react';
import qs from 'qs';

// Components
import { ICON_TYPES, Icon } from '../Icon/Icon';
import { CmsLink } from '../Link/CmsLink';

/**
 * Social share icon
 *
 * @param baseUrl Sharing url basepath
 * @param url Metadata url
 * @param hashtags  Metadata hashtags
 * @param symbol  Icon Symbol
 * @param color Icon Color
 * @param title Metadata title
 * @param image Metadata image
 * @param trackingCategory Tracking Category name
 * @returns {*}
 * @constructor
 */

interface SocialShareIconProps {
  baseUrl: string;
  url: string;
  symbol: string;
  color: string;
  image?: string | string[] | null;
  title?: string;
  hashtags?: string[];
  trackingCategory?: string | null;
}

function SocialShareIcon({
  baseUrl,
  url,
  symbol,
  color,
  hashtags = [],
  title = '',
  image = null,
  trackingCategory = null,
}: Readonly<SocialShareIconProps>) {
  let sharingBody;
  let trackingIcon = symbol;

  switch (symbol) {
    case 'facebook':
      sharingBody = qs.stringify({
        u: url,
        hashtags: hashtags.join(','),
      });
      break;
    case 'twitter':
      sharingBody = qs.stringify({
        text: title,
        url,
        hashtags: hashtags.join(','),
      });
      break;
    case 'pinterest':
      sharingBody = qs.stringify({
        url,
        media: image,
        description: title,
      });
      break;
    case 'xing':
      sharingBody = qs.stringify({
        op: 'share',
        url,
      });
      break;
    case 'linkedin':
      sharingBody = qs.stringify({
        mini: 'true',
        url,
        title,
        summary: `\n${title}\n${url}`,
        provider: url,
      });
      break;
    case 'service-mail':
      sharingBody = qs.stringify({
        body: `\n\n\n${title}\n${url}`,
      });
      trackingIcon = 'mail';
      break;
    default:
      return null;
  }

  const link = {
    target: `${baseUrl}${sharingBody}`,
    window: symbol !== 'service-mail' ? '_blank' : '',
    rel: 'nofollow',
  };

  const capitalizeIcon = trackingIcon.charAt(0).toUpperCase() + trackingIcon.slice(1);
  return (
    <CmsLink
      link={link}
      socialTrackingName="Social share"
      socialTrackingIcon={capitalizeIcon}
      trackingCategory={trackingCategory}
    >
      <Icon symbol={symbol} type={ICON_TYPES.WEB20} color={color} className={`icon-${symbol}`} />
    </CmsLink>
  );
}

export default SocialShareIcon;
