// types
import type { IMeta } from 'scenes/meta.types';

// components
import { Translation } from '../Translation/Translation';
import SocialShareView from './SocialShareView';

// utils
import { isEmpty } from 'utils/is-empty';

interface SocialShareProps {
  metaData: IMeta;
  className?: string;
  hashtags?: string[];
  productPage?: boolean;
  types?: string[];
}

export default function SocialShare({
  productPage = false,
  className,
  hashtags = [],
  types,
  metaData,
}: Readonly<SocialShareProps>) {
  if (isEmpty(types)) {
    return null;
  }

  return (
    <SocialShareView
      className={className}
      hashtags={hashtags}
      metaData={metaData}
      types={types}
      headline={
        <Translation
          id={productPage ? 'web20_social_share_headline_product' : 'web20_social_share_headline'}
        />
      }
    />
  );
}
